.ant-layout {
  min-height: 100vh;

  .ant-layout-header  {
      height: 64px;
      position: relative;
      box-shadow: 0 1px 10px rgba(0,21,41,.08);
      background-color: #fff;
      .ant-btn .icon-screen-collapse {
          font-size: 21px;
          color: grey;
      }
      .app-header {
          display: flex;
          justify-content: space-between;
          .app-left-header {
              display: flex;
              align-items: center;
          }
          .app-right-header {
              overflow: hidden;
              .right-header-content {
                  padding: 4px 0;
                  display: inline;
                  cursor: pointer;
              }
          }
      }
  }

  .app-logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 64px;
  }

  .ant-layout-sider {
      height: auto;
      left: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      box-shadow: 10px 5px 10px rgba(0,21,41,.08);
  }

  .ant-menu-item-group-title {
    font-weight: 600;
    color: #e8641a;
  }

  .ant-layout-sider-children {
      background-color: #1B1B1B;
  }

  .ant-menu {
      background-color: #1B1B1B;
      
      .ant-menu-item-group .ant-menu-item-group-list .ant-menu-submenu .ant-menu {
          background-color: #17141E;
      }
  }

  .ant-layout-content {
      background-color: #f0f2f5;
  }

  .ant-layout-footer {
    color: rgb(146, 145, 145);
    text-align: center;
    padding: 16px;
    font-weight: 700;

    p {
        margin: 0;
    }
  
    .app-footer-title {
        color: #e8641a;
    }
    .app-footer-subtitle {
        font-size: 10px;
        font-weight: 400;
    }
  }

}

.ant-drawer {
  position: absolute;
  .app-drawer-logo {
      display: flex;
      justify-content: center;
  }
  .ant-drawer-header {
      background-color: #1B1B1B;
      border-color: #1B1B1B;
  }
  .ant-drawer-wrapper-body {
      background-color: #1B1B1B;
  }

  .ant-menu {
      background-color: #1B1B1B;
      
      .ant-menu-item-group .ant-menu-item-group-list .ant-menu-submenu .ant-menu {
          background-color: #17141E;
      }
  }

  .ant-drawer-body {
      display: flex;
      padding: 0px;

      .ant-menu {
          justify-content: space-between;

      }
  }
}

// Popover items on collapsed sidebar
.ant-menu-submenu  {
  .ant-menu {
      background-color: #17141E;
  }
}

.dashboardContainer {
  table {
    width: 100%;
  }
  table, th {
      background-color: #e8641a;
  }
  .tableStyle,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 2px;
  }
  th,
  td {
    padding: 0px;
    text-align: center;
  }
  table.tableStyle tr:nth-child(even) {
    background-color: #eee;
  }
  table.tableStyle tr:nth-child(odd) {
    background-color: #fff;
  }

}

.app-container {
  padding: 20px;
}